import { render, staticRenderFns } from "./PlacementRow.vue?vue&type=template&id=8adf7e26&"
import script from "./PlacementRow.vue?vue&type=script&lang=js&"
export * from "./PlacementRow.vue?vue&type=script&lang=js&"
import style0 from "./PlacementRow.vue?vue&type=style&index=0&id=8adf7e26&prod&lang=postcss&module=s&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports
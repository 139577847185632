
import { mapGetters } from 'vuex';
import mixins from 'vue-typed-mixins';

import ModuleBlocker from 'UI/ModuleBlocker.vue';
import theme from 'Theme';
import TradingData from 'Modules/Trading/Trading.Data.vue';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import { OrdersTypes, OrdersSides, FuturesOrderSides } from 'Models/trading';
import { getPlacementThemeColors } from 'Config/placementsManifest';
import workspaceModule from 'Mixins/workspaceModule';
import Dropdown from 'Control/Dropdown.vue';
import SwitchControl from 'Control/Switch.vue';
import MarginLimit from 'Modules/Trading/components/SpotMargin/MarginLimit.vue';
import MarginMarket from 'Modules/Trading/components/SpotMargin/MarginMarket.vue';
import FuturesApi from 'Apis/Futures';
import GetPositionModeRequest from 'Entities/futuresOrderRegistrar/GetPositionModeRequest';
import { FUTURES_MODES } from 'Store/v2/Futures';
import { UnifiedExchangesTags } from 'Store/v2/Transfer';
import PublicDataApi from 'Apis/PublicData';
import AssetQuotationsRequest from 'Entities/publicPresenter/AssetQuotationsRequest';

import FuturesCryptoLimit from './components/Futures/FuturesCryptoLimit.vue';
import FuturesSpotMarket from './components/Futures/FuturesSpotMarket.vue';
import FuturesStopLimit from './components/Futures/FuturesStopLimit.vue';
import FuturesStopMarket from './components/Futures/FuturesStopMarket.vue';
import StopMarket from './components/StopMarket.vue';
import StopLimit from './components/StopLimit.vue';
import SpotMarket from './components/SpotMarket.vue';
import CryptoLimit from './components/CryptoLimit.vue';

export default mixins(TradingData).extend<any, any, any, any>({
    name: 'Trading',
    mixins: [workspaceModule, TradingData],
    components: {
        MarginMarket,
        MarginLimit,
        SwitchControl,
        FuturesStopMarket,
        FuturesStopLimit,
        CryptoLimit,
        SpotMarket,
        StopLimit,
        StopMarket,
        FuturesCryptoLimit,
        FuturesSpotMarket,
        AccountColorMarker,
        ModuleBlocker,
        Dropdown,
    },
    props: {
        isModal: {
            type: Boolean,
            default: false,
        },
        placement: {
            type: String,
            required: true,
        },
        pair: {
            type: String,
            required: true,
        },
        uniqueId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            OrdersTypes,
            OrdersSides,
            FuturesOrderSides,
            currentExpiryLabel: 'Good Till Cancelled',
            getPlacementThemeColors,
            changePairListenerId: null,
            changePlacementListenerId: null,
            theme,
            dropdownActiveIndex: 0,
            presetedLeverage: 1,
            presetedQuantity: 0,
            activeFuturesMode: 'HEDGE',
            getPositionModeCache: undefined,
            FUTURES_MODES,
            quotations: [],
        };
    },
    computed: {
        ...mapGetters({
            activeAccount: 'Accounts/activeAccount',
            activeAccountId: 'Accounts/activeAccountID',
            isThemeDark: 'isThemeDark',
            activePlacementName: 'Placements/activeTerminalPlacementName',
        }),
        isCoinMargin() {
            return this.quoteAssetSymbol === 'USD' && this.isCurrentPlacementFutures;
        },
        isCoinMarginContract() {
            if (!this.isCoinMargin) {
                return false;
            }
            const assetPair = this.$store.getters['AssetPairs/GET_ASSET_PAIRS']
                .find(({ symbol }) => symbol.toLowerCase() === this.pair.toLowerCase());
            return assetPair?.contractPrice !== undefined;
        },
        coinMarginContractPrice() {
            if (!this.isCoinMarginContract) {
                return 0;
            }
            const assetPair = this.$store.getters['AssetPairs/GET_ASSET_PAIRS']
                .find(({ symbol }) => symbol.toLowerCase() === this.pair.toLowerCase());
            return Number(assetPair!.contractPrice);
        },
        baseAssetSymbol() {
            return this.pair.split('/')[0];
        },
        quoteAssetSymbol() {
            return this.pair.split('/')[1];
        },
        coinMarginUnifiedAvailableQuantity() {
            const availableQuantity = Number(
                this.$store.state.Margin.totalBalances
                    .find(({ placementTags }) => (
                        placementTags.some((tag: string) => tag === this.activePlacementTag)
                    ))?.availableQuantity ?? 0,
            );
            const rate = this.quotations
                .find(({ assetPairSymbol }) => assetPairSymbol.split('/')[0] === this.baseAssetSymbol)?.rate ?? Infinity;

            return (availableQuantity / rate) * (this.isCoinMarginContract ? this.coinMarginContractPrice : 1);
        },
        unifiedAvailableQuantity() {
            const availableQuantity = Number(
                this.$store.state.Margin.totalBalances
                    .find(({ placementTags }) => (
                        placementTags.some((tag: string) => tag === this.activePlacementTag)
                    ))?.availableQuantity ?? 0,
            );
            const rate = this.quotations
                .find(({ assetPairSymbol }) => assetPairSymbol.split('/')[0] === this.quoteAssetSymbol)?.rate ?? Infinity;

            return availableQuantity / rate;
        },
        isUnifiedExchange() {
            return UnifiedExchangesTags.has(this.activePlacementTag ?? '');
        },
        activePlacementTag() {
            return this.$store.state.Placements.placementNamesToPlacementTags.get(this.placement.toUpperCase());
        },
        maxLeverage() {
            const leverages = this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair)?.placementLeverages;
            if (!leverages) {
                return null;
            }
            return Math.max(...leverages.map((l) => Number(l.level)).sort((a, b) => a - b));
        },
        feesRate() {
            return this.personalFeesRate;
        },
        activePlacement() {
            return this.$store.state.Placements.placements.find(({ name }) => name === this.placement);
        },
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        currentExpiryIndex() {
            return this.expiries.findIndex(({ label }) => label === this.currentExpiryLabel);
        },
        hasEnabledOrderType() {
            return this.getOrdersTypes.some(({ disabled }) => !disabled);
        },
        hasAccountAccess() {
            return this.$store.getters['Accounts/hasActiveAccountPolicy']('trading');
        },
        isActivePlacementLinked() {
            return this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](this.placement);
        },
        isPlacementTradingBlocked() {
            return false;
        },
        isTradingBlocked() {
            return !this.hasAccountAccess || this.isPlacementTradingBlocked;
        },
        isCurrentPlacementFutures() {
            const activePlacementName = this.placement;
            if (!activePlacementName) {
                return false;
            }
            return activePlacementName.toLowerCase().indexOf('futures') !== -1;
        },
    },
    methods: {
        async getQuotations() {
            if (this.isUnifiedExchange) {
                try {
                    const { data: quotations } = await PublicDataApi.publicGetAssetQuotations(new AssetQuotationsRequest({
                        assetSymbols: [this.baseAssetSymbol, this.quoteAssetSymbol],
                        quotationSymbol: 'USD',
                    }));
                    this.quotations = quotations;
                } catch {
                    this.quotations = [];
                }
            }
        },
        activeOrderSide(value) {
            if (this.isCurrentPlacementFutures) {
                return this.$store.getters.localization.Body.Terminal.Trading.FuturesOrderSides[value].toUpperCase();
            }
            return this.$store.getters.localization.Body.Terminal.Trading.OrderSides[value].toUpperCase();
        },
        setActiveOrderType({ el, index }) {
            if (el.disabled) {
                return;
            }
            this.activeOrderTypeIndex = index;
            if (this.getExpiries[0]) {
                this.setExpiry({ item: this.getExpiries[0].label, index: 0 });
            }
        },
        setExpiry({ item, index }) {
            this.currentExpiryLabel = item;
            this.dropdownActiveIndex = index;
        },
        async getCurrentFuturesMode() {
            if (
                !this.isCurrentPlacementFutures
                || !this.pair
                || !this.placement
            ) {
                return;
            }
            try {
                const currentPlacementTag = this.$store.state.Placements.placementNamesToPlacementTags.get(this.placement.toUpperCase());
                if (!currentPlacementTag) {
                    return;
                }

                const request = {
                    accountId: this.activeAccountId,
                    placementTag: currentPlacementTag,
                    contractSymbol: this.pair,
                };
                if (
                    this.getPositionModeCache
                    && this.getPositionModeCache === JSON.stringify(request)
                ) {
                    return;
                }
                this.getPositionModeCache = JSON.stringify(request);

                const { data: mode } = await FuturesApi.privateFuturesGetPositionMode(new GetPositionModeRequest(request));
                this.activeFuturesMode = mode.mode || 'HEDGE';
            } catch {
                this.activeFuturesMode = 'HEDGE';
            }
        },
    },
    async mounted() {
        await this.getCurrentFuturesMode();
        await this.getQuotations();
    },
    watch: {
        async placement() {
            this.activeOrderTypeIndex = 0;
            await this.getCurrentFuturesMode();
            await this.getQuotations();
        },
        async pair() {
            this.activeOrderTypeIndex = 0;
            await this.getCurrentFuturesMode();
            await this.getQuotations();
        },
        async activeAccountId() {
            await this.getCurrentFuturesMode();
        },
    },
});


import Vue from 'vue';
import { mapGetters } from 'vuex';

import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import Placement from 'Entities/publicPresenter/Placement';
import theme from 'Theme';
import AssetValue from 'UI/AssetValue.vue';
import { FUTURES_MODES } from 'Store/v2/Futures';
import { FuturesOrderSides } from 'Models/trading';

interface Computed {
    quotationAssetCharacter: string;
    precision: number;
    currentPlacement: Placement;
    takerRate: number;
    makerRate: number;
}

export default Vue.extend<any, any, Computed, any>({
    props: {
        placement: {
            type: String,
            required: true,
        },
        pair: {
            type: String,
            required: true,
        },
        exchange: {
            type: String,
            required: true,
        },
        orderValue: {
            type: Number,
            default: 0,
        },
        side: {
            type: String,
            required: true,
        },
        fee: {
            type: Number,
            default: 0,
        },
        quotedFee: {
            type: Number,
            default: 0,
        },
        feeAsset: {
            type: String,
            default: '',
        },
        feesRate: {
            type: undefined,
            required: true,
        },
        orderType: {
            type: String,
            default: '',
        },
        isFutures: {
            type: Boolean,
            default: false,
        },
        needBackground: {
            type: Boolean,
            default: true,
        },
        leverage: {
            type: Number,
            default: 1,
        },
        quantityPrecision: {
            type: Number,
            default: 4,
        },
        mode: {
            type: String,
            default: FUTURES_MODES.hedge,
        },
        isSpotMargin: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AssetValue,
    },
    data() {
        return {
            theme,
            FUTURES_MODES,
            FuturesOrderSides,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        currentPlacement() {
            return this.$store.getters['Placements/getPlacementByName'](this.placement);
        },
        precision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
        takerRate() {
            if (this.feesRate) {
                return Number(this.feesRate.taker);
            }
            return Number(this.currentPlacement.commissionTaker);
        },
        makerRate() {
            if (this.feesRate) {
                return this.feesRate.maker;
            }
            return Number(this.currentPlacement.commissionMaker);
        },
    },
});

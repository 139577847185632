// This file was autogenerated. Please do not change.
export interface IQuoteAssetsRequest {
    assetSymbols?: string[];
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    placementTag?: string;
}

export default class QuoteAssetsRequest {
    readonly _assetSymbols: string[] | undefined;

    /**
     * Example: BTC,USDT
     */
    get assetSymbols(): string[] | undefined {
        return this._assetSymbols;
    }

    readonly _includeTotal: boolean | undefined;

    /** Description: Add total count to response */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /** Description: Page number */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /** Description: Elements' count per page */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementTag: string | undefined;

    /**
     * Description: Placement platform Tag
     * Example: BINANCE_FUTURES
     */
    get placementTag(): string | undefined {
        return this._placementTag;
    }

    constructor(props: IQuoteAssetsRequest) {
        if (props.assetSymbols) {
            this._assetSymbols = props.assetSymbols;
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementTag === 'string') {
            this._placementTag = props.placementTag.trim();
        }
    }

    serialize(): IQuoteAssetsRequest {
        const data: IQuoteAssetsRequest = {
        };
        if (typeof this._assetSymbols !== 'undefined') {
            data.assetSymbols = this._assetSymbols;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementTag !== 'undefined') {
            data.placementTag = this._placementTag;
        }
        return data;
    }

    toJSON(): IQuoteAssetsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetSymbols: !this._assetSymbols ? true : this._assetSymbols.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementTag: !this._placementTag ? true : typeof this._placementTag === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}

import { render, staticRenderFns } from "./PairRow.vue?vue&type=template&id=00a86948&"
import script from "./PairRow.vue?vue&type=script&lang=ts&"
export * from "./PairRow.vue?vue&type=script&lang=ts&"
import style0 from "./PairRow.vue?vue&type=style&index=0&id=00a86948&prod&lang=postcss&module=s&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports
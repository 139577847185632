
/* eslint-disable no-nested-ternary */
import Vue from 'vue';
import { mapGetters } from 'vuex';

import SpotAssetPair from 'Entities/publicPresenter/SpotAssetPair';
import Asset from 'Entities/publicPresenter/Asset';
import MarketDataApi from 'Apis/MarketData';
import TradingDataRequest from 'Entities/publicPresenter/TradingDataRequest';
import TradingData from 'Entities/publicPresenter/TradingData';
import PublicDataApi from 'Apis/PublicData';
import FavoriteAssetPair from 'Entities/userSettings/FavoriteAssetPair';
import SpotAssetPairRequest from 'Entities/publicPresenter/SpotAssetPairRequest';
import SpotAssetPairsRequest from 'Entities/publicPresenter/SpotAssetPairsRequest';
import { ADD_ASSET_PAIR, SET_ACTIVE_ASSET_PAIR } from 'Store/v2/TradingData';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import {
    ADD_SPOT_ASSET_PAIR,
    allQuoteAssetsArray,
    setActiveTerminalAssetPairId,
    updateSpotAssetPairsList,
} from 'Store/v2/AssetPairs';
import BlockHeader from 'UI/BlockHeader.vue';
import theme from 'Theme';
import Icon from 'UI/Icon.vue';
import HeaderSwitcher from 'Control/HeaderSwitcher.vue';
import NoData from 'UI/NoData.vue';
import QuoteAssetsRequest from 'Entities/publicPresenter/QuoteAssetsRequest';
import { changeScroll } from 'Lib/utils/horizontalScroll';

import PairRow from './ChoosePair/PairRow.vue';

const emptyFilterItem = 'ALL';

enum SORTING_MODES {
    ACTIVE_SYMBOL = 'ACTIVE_SYMBOL',
    ACTIVE_SYMBOL_REVERSE = 'ACTIVE_SYMBOL_REVERSE',
    ACTIVE_VOLUME24H = 'ACTIVE_VOLUME24H',
    ACTIVE_VOLUME24H_REVERSE = 'ACTIVE_VOLUME24H_REVERSE',
    DEFAULT = 'DEFAULT',
}

interface Data {
    search: string;
    assetFilterIndex: number;
    sortingMode: SORTING_MODES;
    activeTab: number;
    emptyFilterItem: 'ALL'
    sortingModes: Record<SORTING_MODES, SORTING_MODES>;
    searchedValues: any[];
    quoteAssetFilteredValues: any[];
    isSearch: boolean;
    searchCallNumber: number;
    favoriteAssetPairs: any[];
    isLoading: boolean;
    theme: any;
    quoteAssets: string[];
    selectedQuoteAsset: string;
    DEFAULT_QUOTE_ASSET: string;
}

interface Methods {
    tableScrollListener: (event: any) => void,
    getSimplifySymbol: (value: string) => string;
    setActiveTab: (data: number) => void;
    toggleSortSymbol: () => void;
    toggleSortBy24h: () => void;
    onChoosePair: (pairId: any) => any;
    pairMaker: (pair: TradingData, spotPair: any) => any;
    isFavorite: (data: string) => boolean;
    makeFavoritePairs: (data: FavoriteAssetPair[]) => any;
    makeFilteredFavoriteAssetPairs: () => any;
    removeFavorite: () => void;
    onSearch: (e: string) => void;
    getQuoteAssets: () => void;
    onWheel: (e: WheelEvent) => void;
    searchAssetPairs: (data: string) => void;
}

interface Computed {
    searchString: string;
    filteredPairs: SpotAssetPair[];
    assetFilter: any;
    sortedPairs: any;
    filterAssets: ('ALL' | Asset)[];
    activePlacementName: string;
    activePlacementTag: string;
    favoriteTools: any;
    filteredFavoriteAssetPairs: SpotAssetPair[];
}
export default Vue.extend<Data, Methods, Computed, any>({
    name: 'ChooseTool',
    components: {
        NoData,
        HeaderSwitcher,
        Icon,
        PairRow,
        BlockHeader,
    },
    props: {
        pairs: {
            type: Array,
            required: true,
        },
    },
    data() {
        const DEFAULT_QUOTE_ASSET = 'All';
        return {
            search: '',
            assetFilterIndex: 0,
            sortingMode: SORTING_MODES.DEFAULT,
            activeTab: 0,
            emptyFilterItem,
            sortingModes: SORTING_MODES,
            searchedValues: [],
            quoteAssetFilteredValues: [],
            isSearch: false,
            searchCallNumber: 0,
            favoriteAssetPairs: [],
            isLoading: false,
            theme,
            quoteAssets: [],
            DEFAULT_QUOTE_ASSET,
            selectedQuoteAsset: DEFAULT_QUOTE_ASSET,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            currentPlacementId: 'Placements/activeTerminalPlacementId',
            currentAssetPairSymbol: 'AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR',
        }),
        filteredFavoriteAssetPairs() {
            const activeAssetPair = this.pairs.find(({ symbol }) => symbol === this.currentAssetPairSymbol?.symbol);
            if (this.isSearch) {
                return this.favoriteAssetPairs.filter(({ symbol }) => (
                    symbol.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
                    && (
                        symbol?.toLowerCase().endsWith(`/${this.selectedQuoteAsset.toLowerCase()}`)
                        || this.selectedQuoteAsset === this.DEFAULT_QUOTE_ASSET
                        || symbol === activeAssetPair?.symbol
                    )
                ));
            }
            return this.favoriteAssetPairs.filter(({ symbol }) => (
                symbol?.toLowerCase().endsWith(`/${this.selectedQuoteAsset.toLowerCase()}`)
                || this.selectedQuoteAsset === this.DEFAULT_QUOTE_ASSET
                || symbol === activeAssetPair?.symbol
            ));
        },
        searchString() {
            return this.getSimplifySymbol(this.search);
        },
        filteredPairs() {
            if (this.isSearch) {
                return this.searchedValues.filter(({ id }) => id);
            }
            if (this.selectedQuoteAsset !== this.DEFAULT_QUOTE_ASSET) {
                return this.quoteAssetFilteredValues.filter(({ id }) => id);
            }
            return this.pairs;
        },
        assetFilter() {
            const currentFilter = this.filterAssets[this.assetFilterIndex];
            if (currentFilter) {
                return currentFilter === 'ALL' ? currentFilter : currentFilter.symbol;
            }
            return emptyFilterItem;
        },
        sortedPairs() {
            return [...this.filteredPairs].sort(({ symbol: a }, { symbol: b }) => {
                if (a === this.currentAssetPairSymbol?.symbol) {
                    return -1;
                }
                if (b === this.currentAssetPairSymbol?.symbol) {
                    return 1;
                }
                return 0;
            });
        },
        filterAssets() {
            const assetsSet = new Set();

            this.pairs.forEach((pair) => {
                assetsSet.add(pair.quoteAssetSymbol);
                assetsSet.add(pair.baseAssetSymbol);
            });

            const assetsList: Asset[] = [...assetsSet].map((assetSymbol) => this.$store.getters['Assets/GET_ASSET_BY_SYMBOL'](assetSymbol));

            return [this.emptyFilterItem, ...assetsList];
        },
        activePlacementName() {
            const { Placements: placements } = this.$store.state;
            const activePlacementName = placements.placements.find(({ id }) => id === placements.activeTerminalPlacementId);
            return activePlacementName?.name || '';
        },
        activePlacementTag() {
            return this.$store.state.Placements.placementNamesToPlacementTags
                .get(this.activePlacementName.toUpperCase()) ?? '';
        },
        favoriteTools() {
            return this.$store.state.AssetPairs.favoriteTools
                .filter(({ placementName }) => placementName.toLowerCase() === this.activePlacementName.toLowerCase());
        },
    },
    methods: {
        onWheel(e) {
            changeScroll(e, this.$refs.Quotes as Element);
        },
        async getQuoteAssets() {
            if (!this.activePlacementTag) {
                return;
            }

            try {
                this.selectedQuoteAsset = this.DEFAULT_QUOTE_ASSET;
                const allAssets: string[] = [];
                const { data: assets, headers } = await PublicDataApi.publicGetQuoteAssets(new QuoteAssetsRequest({
                    assetSymbols: allQuoteAssetsArray,
                    includeTotal: true,
                    page: 1,
                    perPage: 100,
                    placementTag: this.activePlacementTag,
                }), true);
                allAssets.push(...assets);

                if (headers) {
                    const { totalPage } = parsePaginationHeaders(headers);
                    if (totalPage && totalPage > 1) {
                        for (let i = 2; i <= totalPage; i += 1) {
                            // eslint-disable-next-line no-await-in-loop
                            const { data: extraAssets } = await PublicDataApi.publicGetQuoteAssets(new QuoteAssetsRequest({
                                page: i,
                                perPage: 100,
                                placementTag: this.activePlacementTag,
                            }));
                            allAssets.push(...extraAssets);
                        }
                    }
                }

                this.quoteAssets = allAssets;
            } catch {
                this.quoteAssets = [];
            }
        },
        async removeFavorite() {
            await this.makeFilteredFavoriteAssetPairs();
        },
        async makeFilteredFavoriteAssetPairs() {
            this.favoriteAssetPairs = await this.makeFavoritePairs(this.favoriteTools);
        },
        async makeFavoritePairs(tools) {
            this.isLoading = true;
            const result: any[] = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const t of tools) {
                try {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: assetPairPair } = await PublicDataApi.publicGetSpotAssetPair(new SpotAssetPairRequest({
                        placementName: t.placementName,
                        symbol: t.symbol,
                    }));
                    // eslint-disable-next-line no-await-in-loop
                    const { data: tradingDataPair } = await MarketDataApi.publicGetTradingData(new TradingDataRequest({
                        assetPairSymbol: t.symbol,
                        placementName: t.placementName,
                    }));
                    result.push(this.pairMaker(tradingDataPair[0], assetPairPair));
                } catch {
                    // api error
                }
            }
            this.isLoading = false;
            return result;
        },
        isFavorite(symbol) {
            return !!this.$store.state.AssetPairs.favoriteTools.find((t) => t.symbol === symbol && t.placementName === this.$store.getters['Placements/activeTerminalPlacementName']);
        },
        pairMaker(tradingDataPair, spotAssetPairsPair) {
            if (!tradingDataPair || !spotAssetPairsPair) {
                return {};
            }
            const asset = this.$store.state.Assets.assets.find((a) => a.symbol === tradingDataPair.symbol!.split('/')[0]);
            const rate = asset && this.$store.state.Assets.quotations.has(asset.symbol)
                ? this.$store.state.Assets.quotations.get(asset.symbol)![this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL']]
                : null;
            spotAssetPairsPair.ticker = {};
            spotAssetPairsPair.ticker.price = tradingDataPair.currentPrice;
            spotAssetPairsPair.ticker.percentChange = tradingDataPair.priceChangePercent24h;
            spotAssetPairsPair.ticker.volume24h = tradingDataPair.volume24h;
            spotAssetPairsPair.ticker.volume24h_usd = rate ? tradingDataPair.volume24h! * rate : null;
            spotAssetPairsPair.ticker.high24h = tradingDataPair.high24h;
            spotAssetPairsPair.ticker.low24h = tradingDataPair.low24h;
            return spotAssetPairsPair;
        },
        async tableScrollListener(e) {
            if (this.isSearch) {
                return;
            }
            const { state: { Placements } } = this.$store;
            const { state: { AssetPairs } } = this.$store;
            const { scrollTop, scrollHeight, offsetHeight } = e.target;
            if (Math.ceil(scrollTop) + offsetHeight === scrollHeight || Math.floor(scrollTop) + offsetHeight === scrollHeight) {
                if (AssetPairs.spotAssetPairs.size !== 0) {
                    await this.$store.dispatch(updateSpotAssetPairsList({ placementId: Placements.activeTerminalPlacementId, isFirstDownload: false }));
                }
            }
        },
        getSimplifySymbol(string) {
            return string ? string.toLowerCase().replace(/[ ]/g, '').replace(/[/]/g, '') : '';
        },
        setActiveTab(index) {
            this.activeTab = index;
        },
        toggleSortSymbol() {
            switch (this.sortingMode) {
                case SORTING_MODES.DEFAULT:
                    this.sortingMode = SORTING_MODES.ACTIVE_SYMBOL;
                    break;
                case SORTING_MODES.ACTIVE_SYMBOL:
                    this.sortingMode = SORTING_MODES.ACTIVE_SYMBOL_REVERSE;
                    break;

                case SORTING_MODES.ACTIVE_SYMBOL_REVERSE:
                    this.sortingMode = SORTING_MODES.DEFAULT;
                    break;

                default:
                    this.sortingMode = SORTING_MODES.DEFAULT;
                    break;
            }
        },
        toggleSortBy24h() {
            switch (this.sortingMode) {
                case SORTING_MODES.DEFAULT:
                    this.sortingMode = SORTING_MODES.ACTIVE_VOLUME24H;
                    break;
                case SORTING_MODES.ACTIVE_VOLUME24H:
                    this.sortingMode = SORTING_MODES.ACTIVE_VOLUME24H_REVERSE;
                    break;

                case SORTING_MODES.ACTIVE_VOLUME24H_REVERSE:
                    this.sortingMode = SORTING_MODES.DEFAULT;
                    break;

                default:
                    this.sortingMode = SORTING_MODES.DEFAULT;
                    break;
            }
        },
        async onChoosePair(pair) {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                this.$store.commit(ADD_SPOT_ASSET_PAIR(pair));
                this.$store.commit(ADD_ASSET_PAIR(pair));
                this.$store.commit(SET_ACTIVE_ASSET_PAIR(pair));
                await this.$store.dispatch(setActiveTerminalAssetPairId(pair.id));
                this.$emit('choose-pair', pair.id);

                const scrollableBlock = document.querySelector('#choosePairScrollable');
                if (scrollableBlock) {
                    scrollableBlock.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        onSearch(e) {
            this.search = e;
        },
        async searchAssetPairs(val) {
            try {
                if (this.activeTab === 0) {
                    this.isLoading = true;
                }
                const num = this.searchCallNumber;
                this.searchCallNumber += 1;
                if (val === '') {
                    this.isSearch = false;
                    return;
                }
                this.isSearch = true;
                const { data: res } = await MarketDataApi.publicGetTradingData(new TradingDataRequest({
                    assetPairSymbol: val.toUpperCase(),
                    placementName: this.activePlacementName,
                }));

                const { data: result, headers } = await PublicDataApi.publicGetSpotAssetPairs(new SpotAssetPairsRequest({
                    placementName: this.activePlacementName,
                    assetPairSymbols: res.map((p) => (p.symbol ? p.symbol : '')),
                    page: 1,
                    perPage: 300,
                }), true);
                let spotAssetPairsResult = result;
                let totalPage;
                if (headers) {
                    totalPage = parsePaginationHeaders(headers).totalPage;
                }
                for (let i = 2; i <= totalPage; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraRes } = await PublicDataApi.publicGetSpotAssetPairs(new SpotAssetPairsRequest({
                        placementName: this.activePlacementName,
                        assetPairSymbols: res.map((p) => (p.symbol ? p.symbol : '')),
                        page: i,
                        perPage: 300,
                    }));
                    spotAssetPairsResult = [...spotAssetPairsResult, ...extraRes];
                }

                if (this.searchCallNumber === num + 1) {
                    const activeAssetPair = this.pairs.find(({ symbol }) => symbol === this.currentAssetPairSymbol?.symbol);
                    this.searchedValues = [];
                    const tempArray = res
                        .map((p) => this.pairMaker(p, spotAssetPairsResult.find((r) => r.symbol === p.symbol)))
                        .filter(({ symbol }) => (
                            symbol?.toLowerCase().endsWith(`/${this.selectedQuoteAsset.toLowerCase()}`)
                            || this.selectedQuoteAsset === this.DEFAULT_QUOTE_ASSET
                        ));
                    if (activeAssetPair && !tempArray.some(({ symbol }) => symbol === activeAssetPair.symbol)) {
                        tempArray.unshift(activeAssetPair);
                    }
                    this.searchedValues = tempArray;
                }
            } finally {
                this.isLoading = false;
            }
        },
    },
    async mounted() {
        await this.getQuoteAssets();
    },
    watch: {
        async selectedQuoteAsset(val) {
            if (val === this.DEFAULT_QUOTE_ASSET) {
                this.quoteAssetFilteredValues = [];
                return;
            }
            if (this.isSearch) {
                await this.searchAssetPairs(this.search);
                return;
            }
            try {
                const { data: res } = await MarketDataApi.publicGetTradingData(new TradingDataRequest({
                    assetPairSymbol: `/${this.selectedQuoteAsset}`,
                    placementName: this.activePlacementName,
                }));

                const { data: result, headers } = await PublicDataApi.publicGetSpotAssetPairs(new SpotAssetPairsRequest({
                    placementName: this.activePlacementName,
                    assetPairSymbols: res.map((p) => (p.symbol ? p.symbol : '')),
                    page: 1,
                    perPage: 300,
                }), true);
                let spotAssetPairsResult = result;
                let totalPage;
                if (headers) {
                    totalPage = parsePaginationHeaders(headers).totalPage;
                }
                for (let i = 2; i <= totalPage; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraRes } = await PublicDataApi.publicGetSpotAssetPairs(new SpotAssetPairsRequest({
                        placementName: this.activePlacementName,
                        assetPairSymbols: res.map((p) => (p.symbol ? p.symbol : '')),
                        page: i,
                        perPage: 300,
                    }));
                    spotAssetPairsResult = [...spotAssetPairsResult, ...extraRes];
                }

                const activeAssetPair = this.pairs.find(({ symbol }) => symbol === this.currentAssetPairSymbol?.symbol);
                this.quoteAssetFilteredValues = [];
                const tempArray = res
                    .map((p) => this.pairMaker(p, spotAssetPairsResult.find((r) => r.symbol === p.symbol)))
                    .filter(({ symbol }) => (
                        symbol?.toLowerCase().endsWith(`/${this.selectedQuoteAsset.toLowerCase()}`)
                    ));
                if (activeAssetPair && !tempArray.some(({ symbol }) => symbol === activeAssetPair.symbol)) {
                    tempArray.unshift(activeAssetPair);
                }
                this.quoteAssetFilteredValues = tempArray;
            } finally {
                this.isLoading = false;
            }
        },
        async activePlacementTag() {
            await this.getQuoteAssets();
        },
        currentPlacementId() {
            this.setActiveTab(0);
            if (this.$refs.chooseToolHeader) {
                (this.$refs.chooseToolHeader as any).setActive('', 0);
            }
            this.search = '';
        },
        async search(val) {
            await this.searchAssetPairs(val);
        },
        activeTab(val) {
            if (val === 1) {
                this.makeFilteredFavoriteAssetPairs();
                this.search = '';
            }
        },
        currentAssetPairSymbol() {
            this.search = '';
        },
    },
});
